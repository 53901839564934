.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}

.accountNameWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  
}

.title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.subTitle {
  width: 550px;
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 20px;
}

.icon {
  height: 98px;
  width: 98px;
  line-height: 98px;
  margin: 0 10px 5px 3px;
}

.accountName {
  font-size: 18px;
  color: var(--marine);
  margin-left: 7px;
}

.accountInput {
  margin: 7px;
  width: 346px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
}

.seperator { 
  border-top: 1px solid var(--brown-grey);
  margin: 16px 0;
}

.table {
  color: var(--brown-grey);
  width: 100%; 
  font-size: 14px;  
}
  
.table input {
  margin: 7px 0px;
  width: 380px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
}

.table input:disabled, .account-input:disabled, .reset:disabled, .submit:disabled {
  opacity: 0.35;
}

.table td:first-child {
  width: 146px;
}

.table input::placeholder {
  color: var(--bb-grey);
}

.table input:focus {
  /* box-shadow:2px 2px 2px 2px rgba(0,0,0,0.2); */
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.note {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 3px;
  /* color: var(--lipstick); */
}