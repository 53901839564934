.component {
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);  
  border-bottom: solid 1px var(--pinkish-grey);
}

.graph {
  background-color: var(--fully-white);
  margin: 0 12px 5px 12px;
  padding-top: 5px;
  height: 110px;
  transition: height 0.15s linear;
}

.graph.hidden {
  padding-top: 0px;
  margin-bottom: 0px;
  visibility: hidden;
  height: 0;
  transition: height 0.15s linear;
}

.chart {
  margin-bottom: 10px;
}

.bar {
  cursor: crosshair;
}

.count {
  padding: 5px 0 5px 85px;
  font-size: 12px;
  color: var(--brown-grey);
}

.customTooltip {
  box-shadow: 0 1px 7px 0 #b2b2b2;
  background-color: var(--fully-white);
  /* backdrop-filter: blur(2px);  */
  padding: 15px;
  margin: 0 100px 0 100px;
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

.customTooltipDate {
  font-size: 12px;
  font-weight: bold;
  color: var(--marine);
}

.customTooltipTime {
  font-size: 12px;
  color: var(--marine);
  margin-bottom: 15px;
}

.customTooltipText {
  font-size: 12px;
  color: var(--brown-grey);
}

.expansionWrapper {
  display: flex;
  flex-direction: row;
}
.expansion {
  margin: 0 20px 0 auto;
  width: 23px;
  object-fit: contain;
  cursor: pointer;
  align-self: end;
}
