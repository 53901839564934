.component {
  margin: 23px 0;
  height: 200px;
}

/* .title {
  font-size: 20px;
  color: var(--brown-grey);
  margin-bottom: 10px;
} */

.customTooltip {
  box-shadow: 0 1px 7px 0 #b2b2b2;
  background-color: var(--fully-white);
  /* backdrop-filter: blur(2px);  */
  padding: 15px;
  display: grid;
  grid-template-columns: max-content min-content max-content;
  align-items: center;
  z-index: 10000;  
}

.customTooltipTitle {
  font-size: 12px;
  color: var(--marine);
  margin-bottom: 15px;
  text-align: center;
  grid-column: 1 / -1;
}

.customTooltipText {
  font-size: 12px;
  color: #b2b2b2;
}

.customTooltipColor {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 15px;
}

.customTooltipTotal {
  grid-column: 1 / -1;
  font-size: 12px;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--brown-grey);
}

.limit {
  font-size: 12px;
  font-weight: 500;
  color: var(--lipstick);
  margin-left: 62px;
}

.limit span {
  color: var(--marine);
  text-decoration: underline;
  cursor: pointer;
}