.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.form {
  margin: 20px;
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
}

.title {
  font-size: 30px;
  color: var(--brown-grey);
  margin-bottom: 14px;
}

.label {
  font-size: 20px;
  color: var(--brown-grey);
  margin-bottom: 14px;
}

.input {
  margin: 7px 0px;
  width: calc(100% - 20px);
  height: 36px;
  border: solid 1px var(--bb-grey);
  font-size: 12px;
  text-align: left;
  padding: 0 5px;
  color: var(--marine);
  background-color: var(--fully-white);
  outline-style:none;
  box-shadow:none;
}

.input::placeholder {
  color: var(--bb-grey);
}

.input:focus {
  /* box-shadow:2px 2px 2px 2px rgba(0,0,0,0.2); */
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}


.submit  {
  margin-top: 20px;
  width: 119px;
	height: 38px;
  font-size: 18px;
  background-color: var(--marine);
  color: var(--fully-white);
  align-self: center;
  cursor: pointer;
}
.submit:hover  {
  background-color: var(--brown-grey);
}

.error {
  font-size: 15px;
  color: var(--lipstick);
}

.login {
  width: 100%;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  border-top: solid 1px var(--pinkish-grey);
  padding-top: 13px;
  color: var(--marine);
  font-size: 15px;
  text-align: center;
  text-decoration: none;
}

.login:hover {
  text-decoration: underline;
}