.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}

.title {  
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.subTitle {
  width: 550px;
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 20px;
}

.memberList {
  margin-bottom: 20px;
}

.memberInfo {
  width: 521px;
  height: 58px;
  padding-right: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  margin: 3px 3px 3px 0;
}

.memberInfo:nth-child(even) {
  background-color: var(--fully-white);
}

.memberInfo:nth-child(odd) {
  background-color:  var(--light-grey);
}

.memberIdentity {
  flex-grow: 1;
}

.hidden {
  visibility: hidden;
}

.inviteInfo {
  width: 542px;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  margin: 3px 3px 3px 0;
}

.inviteInfo:nth-child(even) {
  background-color: var(--fully-white);
}

.inviteInfo:nth-child(odd) {
  background-color:  var(--light-grey);
}

.fullName {
  font-size: 18px;
  color: var(--marine);
}

.email {
  font-size: 14px;
  color: var(--brown-grey);
  font-weight: 300;
}

.inviteInfo .email {
  flex-grow: 1;
  padding-left: 20px; 
}

.memberIcon {
  height: 40px;
  width: 40px;
  margin: 0 13px 0 20px;
}

.resend {
  color: var(--marine);
  margin: 0 10px 0 21px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
}

.trash {
  width: 11.4px;
  height: 12.3px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
}

.underline {
  width: 100%;
  border-bottom: dotted 3px var(--pinkish-grey);
}

.selectInvite {
  flex-grow: 1;
  margin: 0 10px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
}

.search {
  margin: 20px 3px 400px 0;
  width: 542px;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:  var(--light-grey);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.addButton {
  color: var(--marine);
  padding: 0;
  border: none;
  background: none;
  margin: 0 10px 0 21px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}


/* The dropdown container */
.dropdown {
  overflow: visible;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* Dropdown content (hidden by default) */
.dropdownContent {
  overflow: visible;
  display: block;
  min-width: 150px;
  position: absolute;
  background-color: var(--fully-white);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  color: var(--marine);
  z-index: 10;
}

.dropdownContent div {
  text-transform: lowercase;
  padding: 10px;
  cursor: pointer;
}

.dropdownContent div:first-letter {
  text-transform: uppercase;
}

.dropdownContent div:hover {
  background-color: var(--pinkish-grey)
}

.role {
  width: 86px;
  text-transform: lowercase;
  font-size: 14px;
  color: var(--marine);
  display:inline-block;
  background-image: url('../../img/extra-triangle.svg');
  background-repeat: no-repeat;
  background-size: 7.6px 6.6px;
  background-position: right center;
}
.role:first-letter {text-transform:uppercase}

.disabled {
  opacity: 0.35;
  cursor: auto;
}

