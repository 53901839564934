.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}

.title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.icon_holder{
  display:inline-block;
  width:70px;
  vertical-align:top;
  cursor: pointer;
}
.icon_selector_holder{
  position: relative;
}
.icon_selector_list{
  float:left;
  display: block;
  max-width: 300px;
  margin-right: 60px;
  padding:5px;
  border-left:solid 1px var(--brown-grey);
}
.icon_selector_list img{
    margin: 5px;
} 

.icon_selector_button_holder{
  text-align:right;
  padding-top: 10px;
  width: 100%;
  clear:both;
}

.icon_selector{
  max-width: 500px;
  height: auto;
  background-color: white;
  position: absolute;

  padding: .5em 1em;
  backdrop-filter: blur(2px);
  box-shadow: 0 0 19px 0 var(--brown-grey);
  background-color: var(--light-grey);
  transition: .3s ;
  z-index: 1;
}


.icon {
  height: 98px;
  width: 98px;
  line-height: 98px;
  margin: 0 10px 5px 3px;
}
.required_indicator{
  color: red;
  font-size:14px;
}

.img_pen {
  width: 11.4px;
  height: 13px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 3px;
  vertical-align: top;
}
.userBorder {
  height: 40px;
  width: 40px;
  /* border: solid 2px var(--marine);
  border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.clickable{
  cursor: pointer;
}
.seperator { 
  border-top: 1px solid var(--brown-grey);
  margin: 16px 0;
}

.table {
  color: var(--brown-grey);
  width: 100%; 
  font-size: 18px;  
}
  
.table input {
  margin: 7px 0px;
  width: 380px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-color:transparent;  
}

.table input:enabled {
  background-image: url('../../img/preferences/pen.svg');
  background-repeat: no-repeat;
  background-size: 11.4px 13px;
  background-position: right 10px center;
}

.table input.no_padding {
  padding: 0px 0px;
}

.table input:disabled, .account-input:disabled, .reset:disabled, .submit:disabled {
  opacity: 0.35;
}

.table td:first-child {
  width: 146px;
}

.table input::placeholder {
  color: var(--bb-grey);
}

.table input:focus {
  /* box-shadow:2px 2px 2px 2px rgba(0,0,0,0.2); */
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.reset_small {
  margin-right: 10px;
  width: 99px;
	height: 28px;
  font-size: 14px;
  background-color: var(--brown-grey);
  color: var(--fully-white);
  cursor: pointer;
}
.reset_small:hover{
  background-color: var(--bb-grey);
}

.submit_small  {
  width: 99px;
	height: 28px;
  font-size: 14px;
  background-color: var(--marine);
  color: var(--fully-white);
  cursor: pointer;
}
.submit_small:hover {
  background-color: var(--brown-grey);
}
