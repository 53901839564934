.component {
  margin: 23px var(--margin) 23px 20px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.failedPayment {
  font-size: 14px;
  color: var(--lipstick);
}

.title {  
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.subTitle {
  width: 550px;
  font-size: 14px;
  color: var(--marine);
}

.bodyHeader {
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: var(--marine);
}

.body {
  font-size: 12px;
  color: var(--marine);
}

.buttonWrapper {
  display: flex;
}

.space {
  flex: 1;
}

.button {
  margin-top: 20px;
  width: 119px;
	height: 38px;
  font-size: 18px;
  background-color: var(--marine);
  color: var(--fully-white);
  cursor: pointer;
}

.button:hover {
  background-color: var(--brown-grey);
}

.seperator { 
  border-top: 1px solid var(--brown-grey);
  margin: 16px 0;
}

.history {
  border-collapse: collapse;
}
.history th {
  font-weight: 500;
  font-size: 14px;
  color: var(--brown-grey);
  text-align: left;
}

.history td {
  font-size: 14px;
  color: var(--brown-grey);
}
.history td:nth-child(1) {
  width: 160px;
}
.history td:nth-child(2) {
  width: 200px;
}
.history td:nth-child(3) {
  width: 100px;
}

.history td:nth-child(4) {
  width: 100px;
}

.history td:nth-child(5) {
  width: 40px;
}

.history tr:not(:first-child,:last-child) {
  border-bottom: solid 1px var(--ice-blue-dark);
} 

