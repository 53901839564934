.keyValueList {
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 54px;

}

.keyValue {
  font-size: 12px;
  line-height:21px;
  color: var(--marine);
  border-radius: 4px;
  border: solid 1px var(--marigold);
  padding: 0 5px 0 5px; 
  margin: 2px 2px 2px 2px;
  white-space: nowrap;
  background-color: var(--fully-white);
}

.severityList {
  display: flex;
  flex-direction: row;  
  align-items: center;
  align-content: center;
}

.severityOff {
  opacity: 0.25;
}

.severityIcon {
  width: 22px;
  height: 22px;
  margin-right:3px;
}
