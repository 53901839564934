
/* The dropdown container */
.dropdown {
  overflow: visible;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: min-content;
}

/* Dropdown content (hidden by default) */
.dropdownContent {
  overflow: auto;
  display: block;
  max-height: 300px;
  min-width: 150px;
  position: absolute;
  background-color: var(--fully-white);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  color: var(--marine);
  z-index: 10;
}

.dropdownContent div {
  padding: 10px;
  cursor: pointer;
}

.dropdownContent div:hover {
  background-color: var(--pinkish-grey)
}

.dropdownFlex {
  display:flex;
  align-items: center;
  height: 100%;
}
.select {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  font-size: 14px;
  color: var(--marine);
  display:inline-block;
}

.centered {
  text-align: center
}

.extraTriangle {
  width: 7.6px;
  height: 6.6px;
  object-fit: contain;
  margin: 0 21px 2px 10px;
}