.component{
  width: 100vw;
  height: 100vh;
  display: flex;
}

.main {
  flex: 1;
  margin: auto;
  overflow-y: auto;
  max-height: 100vh;
}

.sideComponent {
  flex: 1;
  min-width: 649px;
  background-color: var(--ice-blue-dark);
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1020px) {
  .sideComponent { display: none; }
}


.topBubbles{
  position: absolute;
  top: 47px;
  right: 43px;
  width: 66px;
  height: 87px;
}

.bottomBubbles{
  position: absolute;
  width: 48px;
  height: 114px;
  left: 50px;
  bottom: 43px;
}

.sideMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideImage {
  width: 580px;
  height: 329px;
  object-fit: contain;
}

.sideTitle {
  margin: 18px 0 7px 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--marine);
}

.sideBody {
  font-size: 12px;
  width: 332px;
  color: var(--brown-grey);
  text-align: center;
}