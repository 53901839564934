.component {
  flex: 1;
  /* margin-right: 10px; */
  height: 38px;
  position: relative;
}

.menuTitle {
  color: var(--turquoise);
  padding: 16px 14px 4px 14px;
  margin-bottom: 16px;
  border-bottom: dotted 3px var(--pinkish-grey);    
  font-size: 15px;
  text-transform: uppercase;
}