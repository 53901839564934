/* Row */
.rowBase {
  position: relative;
  background-color: var(--fully-white);
  transition: .3s;
  cursor: pointer;
}

.row {
  height: 40px;
  display: flex;
  
  padding: 0 11px;
  align-items: center;
  min-width: 0;
  background-color: var(--light-grey);
}

.row:after {
  content : "";
  position: absolute;
  left: 11px;
  right: 11px;
  bottom: 0;
  height: 0;
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
}

.row.unread {
  background-color: var(--fully-white);
}

.rowPercentage {
  width: 60px;
  font-size: 15px;
}

.rowSum {
  width: 45px;
  font-size: 13px;
  color: var(--brown-grey);
}

.rowLocation {
  width: 240px;
  font-size: 12px;
  color: var(--brown-grey);
}

.rowMessage {
  flex: 1;
  font-size: 12px;
  color: var(--marine);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.row.unread .rowMessage{
  font-weight: bold;
}

.rowOsIcon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-left: 20px;
  object-fit: contain;
}

.rowOsVersion {
  flex-shrink: 1;  
  width: 100px;
  font-size: 12px;
  color: var(--brown-grey);
  text-overflow: ellipsis;
  white-space: nowrap;

}

.rowAppVersion {
  width: 100px;
  font-size: 12px;
  color: var(--brown-grey);
  margin-right: 8px;
}

.rowLine {
  height: 30px;
  width: 1px;
  opacity: 0.4;
  background-color: var(--pinkish-grey);
}

.rowStatusIcon { 
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.hidden {
  visibility: hidden;
}

.rowTools {
  top: 0;
  height: calc(100% - 1px);
  right: 6px;
  width: 140px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--ice-blue);
  visibility: hidden;
}

.rowBase:hover .row {
  background-color: var(--ice-blue) !important;
}
.rowBase:hover .rowTools {
  visibility: visible;
}

.rowToolIcon {
  height: 16px;
  width: 16px;
  min-width: 16px;
  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  transition: 0.3s;
}

.rowToolIcon:hover {
  background-color: var(--brown-grey);
}