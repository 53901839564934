.mainInformation {
  height: 114px;
  display: flex;
  margin: 20px 20px 0 20px;
  background-color: var(--fully-white);
}

.popupContainer {
  margin: 0 11px 11px 11px;
  cursor: pointer;
}

.severity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.up, .down {
  padding: 10px;
  width: 7.6px;
  height: 6.6px;
  object-fit: contain;
}

.up {
  transform: rotate(180deg);
}

.severityIcon {
  width: 52px;
  height: 52px;
}

.infoMain {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  margin-left: 17px;
}

.infoPercentage {
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
  margin-top: 9px;
  letter-spacing: -0.4px;
}

.infoSeverity {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
}

.infoLocation {
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;
  color: var(--brown-grey);
}

.infoMiddle {
  margin-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.infoMessage {
  font-size: 12px;
  margin-left: 30px;
  margin-top: 6px;
  color: var(--marine);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.rightInfo {
  width: 150px;
  margin-top: 10px;
  margin-left: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(3, 18px) 25px 18px;
  align-items: center;
}

.infoName {
  font-size: 12px;
  color: var(--brown-grey);
  font-weight: 300;
}

.infoValue {
  font-size: 12px;
}

.osVersion {
  display: flex;
  font-size: 12px;
  color: var(--brown-grey);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-column : 1 / span 2;
}

.osIcon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.appVersion {
  font-size: 12px;
  color: var(--brown-grey);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-column : 1 / span 2;
  display: flex;
}
.appVersion div {
  margin-right: 5px;
}

.appVersion div:first-child {
  font-weight: 300;
}

.status {
  position: relative;
  margin: 15px 15px 15px 5px;
}

.customTooltip {
  box-shadow: 0 1px 7px 0 #b2b2b2;
  background-color: var(--fully-white);
  /* backdrop-filter: blur(2px);  */
  padding: 15px;
  display: grid;
  grid-template-columns: max-content min-content max-content;
  align-items: center;
  z-index: 10000;  
}

.line {
  height: 90px;
  width: 1px;
  opacity: 0.4;
  background-color: var(--pinkish-grey);
  align-self: center;
  margin: 0 5px;
}

.actions {
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  padding: 10px 5px;
  visibility: hidden;
  position: absolute;
  background-color: var(--fully-white);
  top: 0;
}

.status:hover .actions {
  visibility: visible;
}

.statusIcon { 
  width: 25px;
  height: 25px;
  margin: 0 5px;
}

.hidden {
  visibility: hidden;
}


.actions img {
  height: 15px;
  width: 15px;
  object-fit: contain;

  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.actions img:hover {
  background-color: var(--pinkish-grey);
}

.space {
  flex: 1;
}


.customTooltipTitle {
  font-size: 12px;
  color: var(--marine);
  margin-bottom: 15px;
  text-align: center;
  grid-column: 1 / -1;
}

.customTooltipText {
  font-size: 12px;
  color: #b2b2b2;
}

.customTooltipColor {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 15px;
}

.customTooltipAlert {
  color: var(--lipstick);
}
