.component {
  height: 100%;
  width: 100%;
  background-color: var(--ice-blue);
}

.subComponent {
  height: 100%;
  width: 350px;
  background-color: var(--fully-white);
}

.newApp {
  display: flex;
  width: calc(100% - 30px);
  padding-left: 30px;
  align-items: center;
  color: var(--tealish);
  height: 54px;
  font-size: 20px;
  line-height: 54px;
  font-weight: 300;
  text-decoration: none;
  border-bottom: solid 1px var(--tealish); 
}

.newApp:hover {
  background-color: var(--ice-blue);
}

.disabled {
  opacity: 0.35;
  pointer-events: "none";
}

.newAppIcon {
  width: 40px;
  height: 36px;
  margin-right: 8px;
}

.appList {
  height: calc(100% - 54px);
  overflow-y: auto;
}

.baseApp {
  display: flex;
  width: calc(100% - 30px - 30px + 10px);  
  height: 54px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  background-color: var(--fully-white);
  padding: 0 30px 0 30px;
}

.baseApp:hover {
  background-color: var(--ice-blue);
}

.underlineApp {
  height: 1px;
  margin: 0 30px 0 30px;
  width: calc(100% - 30px - 30px);
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
}

.app {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--black);
  text-decoration: none;
  transition: 0.3s;
}

.app img {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.trash {
  width: 11.4px;
  height: 13.3px;  
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
  /* border-radius: 50%; */
  transition: .3s;
}

.trash:hover {
  background-color: var(--light-grey);
}
