.loglyticsList {
  flex: 1;
  width: 100%;
  height: 100%;
}

.empty {
  height: 100%;
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--fully-white);
  justify-content: center;
}

.empty img {
  margin-bottom: 50px;
}

.empty div {
  font-size: 25px;
  font-weight: bold;
  color: var(--marine);
}

.empty div div:last-child {
  font-size: 18px;
  font-weight: normal;
  white-space: pre-wrap;
}


.emptyBridge {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--fully-white);
}

.emptyBridge img {
  margin-bottom: 5px;
}

.emptyBridge div {
  font-size: 18px;
  font-weight: bold;
  color: var(--marine);
}

.emptyBridge div div:last-child {
  font-size: 16px;
  font-weight: normal;
  white-space: pre-wrap;
}