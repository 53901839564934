.titleImg {
  width: 20.8px;
  height: 19.3px;
  object-fit: contain;
  flex: 1;
  object-position: left;
}

.xIcon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  cursor: pointer;
}

.body {
  /* overflow: auto; */
  width: 752px;
}

.doc {
  display: grid;
  margin: 8px 16px;
  padding: 30px 19px 30px 19px;
  grid-template-columns: 70px calc(100% - 70px);
  grid-row-gap: 7px;
  background-color: var(--fully-white);
}

.docRowNumber {
  display: flex;
  flex-direction: column;
}

.docTitle {
  font-size: 14px;
  color: var(--brown-grey);
  line-height: 26px;
  cursor: pointer;
}

.docTitleSelected {
  font-size: 26px;
  font-weight: 300;
  color: var(--marine);
  line-height: 26px;
  margin-bottom: 14px;
}

.docBody {
  font-size: 14px;
  color: var(--brown-grey);
}

.docBody span {
  word-wrap: break-word;
  color: var(--marine);
}

.docBodyRemark {
  font-size: 12px;
  color: var(--marine);
}

.copyBox {
  display: flex;
}

.copyBox > pre {
  flex: 1;
}
.copyBox > div {
  padding: 13px 0px 13px 10px;
  display: flex;
  align-items: flex-end;
}

.stageCurrent, .stagePrevious,.stageNext {
  width: 26px;
  height: 26px;
  color: var(--fully-white);
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
}

.stageCurrent {
  background-color: var(--tealish);
}

.stagePrevious {
  background-color: var(--yellowy-green);
}

.stageNext {
  background-color: var(--pinkish-grey);
}

.stagePrevious:hover, .stageNext:hover {
  background-image: url("../../img/preferences/pen-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: rgba(0, 0, 0, 0);
}

.verticalLine {
  flex: 1 1 22px;
  border-left: 1px solid var(--pinkish-grey);
  margin: 7px 0 0 12px;
}

.buttonWrapper {
  margin: 20px 0px 10px 0;

  display: flex;
  justify-content: flex-end;

}
.previous, .next {
	height: 38px;
  padding: 0 15px;
  border: 0;
  color: var(--fully-white);
  cursor: pointer;
  font-size: 15px;
}

.previous  {
  margin-right: 10px;
  background-color: var(--brown-grey);
}
.previous:hover {
  background-color: var(--bb-grey);
}

.next  {
  background-color: var(--marine);
}
.next:hover {
  background-color: var(--tealish);
}

.check {
  height: 200px;
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-direction: row;
}

.checkIcon {
  width: 82px;
  height: 82px;
  margin-right: 7px;
  object-fit: contain;
}

.checkWaitingText {
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.checkProblem {
  margin-top: 50px;
  color: var(--brown-grey);
}

.checkProblem a {
  color: var(--tealish);
  cursor: pointer;
}
.checkProblem a:hover {
  text-decoration: underline;
}

@media (max-width: 794px) { /* component size: 750px + 2 * 22px  */
  .component { width: calc(100vw - 22px);}
  .doc { 
    grid-template-columns: 35px calc(100% - 35px);
  }
  .checkWaitingText { font-size: 16px;}
  .checkProblem { 
    margin-top: 10px;
    font-size: 14px;
  }
}

.checkTextBox {
  margin-top: 10px;
}

.checkSessionTitle, .checkSessionBody {
  width: 368px;
  font-size: 14px;
  color: var(--marine);
}

.checkSessionTitle {
  font-weight: bold;
}

.checkSuccessTitle1, .checkSuccessTitle2 {
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.checkSuccessTitle2 {
  font-weight: bold;
}

.checkSuccessBody1, .checkSuccessBody2 {
  font-size: 14px;
  color: var(--marine);  
}

.checkSuccessBody1 {
  margin-top: 20px;
  font-weight: bold;
}

.checkSuccessBody2 a {
  color: var(--tealish);
}

