.inputButton {
  margin: 40px 0 20px 0px;
  display: flex;
}

.reset, .submit {
  display:block;
  width: 77px;
	height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: var(--fully-white);
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.reset:disabled, .submit:disabled {
  opacity: 0.35;
}

.reset:hover, .submit:hover {
  opacity: .8;
}

.reset  {
  margin-right: 10px;
  background-color: var(--brown-grey);
  color: var(--fully-white);
}

.submit  {
  background-color: var(--tealish);
  color: var(--fully-white);
}