.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}

.title {  
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.subTitle {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  color: var(--tealish);
}

.body {
  width: 550px;
  font-size: 14px;
  color: var(--marine);
}

.keyList {
  margin-bottom: 20px;
}

.keyInfo {
  width: 542px;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  margin: 3px 3px 3px 0;
}

.keyInfo:nth-child(even) {
  background-color: var(--fully-white);
}

.keyInfo:nth-child(odd) {
  background-color:  var(--light-grey);
}


.hidden {
  visibility: hidden;
}

.keyType {
  width: 90px;
  font-size: 14px;
}

.tokenTitleItem{
  width: 50%;
  display: inline-block;
}

.inputName {
  display: flex;
  flex: 1;
  border-color: transparent;
  border: 0px;
  margin: 7px 0px;
  height: 29px;
  border-radius: 4px;
  padding: 0 10px;
  color: var(--marine);
  /* background-color: var(--light-grey); */
  align-items: center;
}

.inputName input {
  flex: 1;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 300;
  flex-grow: 1;
}

.inputName img {
  width: 11.4px;
  height: 13px;
  object-fit: contain;
}

.buttonName {
  width: 65px;
}
.buttonName button{
  margin: 7px 0px;
  background-color: var(--marine);
  height: 29px;
  border: none;
  font-size: 12px;
  margin-left: 10px;
  color: var(--fully-white);
}

.trash {
  width: 11.4px;
  height: 12.3px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
}

.underline {
  width: 542px;
  border-bottom: dotted 3px var(--pinkish-grey);
}

.inputKeyName {
  flex-grow: 1;
  margin: 0 10px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
}


.addPanel {
  margin: 20px 3px 20px 0;
  width: 542px;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:  var(--light-grey);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.tokenText{
  width: 90%;
  height: 100px;
}

.buttonHolder{
   text-align: right;
   align-items: flex-end;
}

.addButton {
  text-align: right;
  align-items: flex-end;
  color: var(--marine);
  padding: 0;
  border: none;
  background: none;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}

.input {
  height: 33px;
  flex: 1;
  background-color: var(--fully-white);
  border: 0px;
  display: flex;
  padding: 0 5px;
  align-items: center;
}

.input input {
  margin-right: 5px;
  border-color:transparent;
  box-shadow:0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-width:  none!important;
}

.edit {
  outline-width:inherit!important;
  box-shadow:0 1px 7px 0 rgba(0, 0, 0, 1);
  border-color:lightgray!important;
}