.component {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
}

.header {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--pinkish-grey);
  background-color: var(--ice-blue);
}

.dateSpan {
  flex: 1;
  font-size: 12px;
  color: var(--marine);
  text-align: center;
}


.currentStatus {
  margin: 15px 5px;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}

.menuIcon {
  width: 14px;
  object-fit: contain;
  align-self: flex-end
}

