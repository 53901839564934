.component {
  margin: 23px var(--margin) 23px 20px;
  overflow-y: auto;
  max-width: 700px;
  padding-right: 40px;
}

.subTitle {
  font-size: 22px;
  font-weight: 300;
  color: var(--tealish);
  margin-bottom: 20px;

  /* color: var(--brown-grey);
  font-weight: 300; */
}
.bodyHeader {
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 5px;
}

.normal {
  font-size: 14px;
  color: var(--brown-grey);
}

.inputUdid {
  display: flex;
  margin: 7px 5px 15px 5px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
  align-items: center;
}
.inputUdid input {
  flex: 1;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 300;
}
.inputUdid button {
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
}

.inputUdid img {
  width: 21.9px;
  height: 21.9px;
  object-fit: contain;
}

.disabledDevice {
  display: flex;
  border-color: transparent;
  margin: 7px 0px;
  height: 29px;
  border-radius: 4px;
  padding: 0 10px;
  color: var(--marine);
  font-size: 14px;
  background-color: var(--light-grey);
  align-items: center;  
}

.disabledDevice div {
  flex: 1;
}

.trash {
  width: 11.4px;
  height: 12.3px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
}

.footnote {
  font-size: 12px;
  color: var(--marine); 
  margin-bottom: 50px;
}