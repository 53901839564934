.component {  
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--light-grey);
}


.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--ice-blue);

}

.addAlert {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.addAlert div {
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  padding: 0 5px;
  color: var(--fully-white);
  background-color: var(--tealish);
  border-radius: 5px;
}
.addAlert:hover {
  opacity: 0.7;
}

.addAlert img {
  width: 40px;
  height: 36px;
  margin: 5px 50px 5px 5px;
}

.noAlerts {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px 18px;
  background-color: var(--fully-white);
}

.noAlertsTitle {
  margin-top: 7px;
  font-size: 26px;
  font-weight: 300;
  padding-left: 60px;
  color: var(--tealish);
}

.noAlertsDescription {
  font-size: 14px;
  padding-left: 60px;
  color: var(--brown-grey);
  margin: 2px 0 26px 0;
}

.noAlertsImg {
  flex: 1;
  padding: 50px 0 0 160px;
}
.noAlertsImg img {
  width: 377px;
  height: 233px;
  object-fit: contain;
}


.alertList {
  background-color: var(--fully-white);
  flex: 1;
  flex-basis: 100px;
  margin: 10px 18px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;


  /* padding-bottom: 1px; */
}

.alertRow {
  flex-basis: 47px;
  padding: 5px 40px 5px 40px;
  min-height: 47px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--fully-white);
  cursor: pointer;
  align-items: center;
}

.alertRow:hover {
  background-color: var(--ice-blue);
}

.alertRow:after {
  content : "";
  left: 18px;
  right: 18px;
  bottom: 0;
  height: 0;
  opacity: 0.4;
  position: absolute;
  /* height: 10px;
  width: 10px; */
  background-color: brown;
  border-bottom: solid 1px var(--pinkish-grey);
}

/* .alertRow:after {
  content : "2";
  position: absolute;
  left: 36px;
  right: 36px;
  bottom: 10px;
  height: 10px;
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
} */

.alertNameCondition {
  min-width: 250px;
  margin-right: 20px;
  
}

.alertName { 
  color: var(--marine);
  font-size: 16px;
  white-space: nowrap;
}

.alertCondition {
  font-size: 12px;
  color: var(--brown-grey);
  white-space: nowrap;
}

.alertSeverities {
  margin: 0 10px 0 25px;
}

.trash {
  width: 11.4px;
  height: 13.3px;  
  padding: 10px 0 10px 10px;
  object-fit: contain;
  cursor: pointer;
  /* border-radius: 50%;  */
  transition: .3s;
}

.trash:hover {
  opacity: 0.8;
  /* background-color: var(--light-grey); */
}

