.container {
  width: 346px;
  min-height: 100%;
  display: flex;
  align-items: left;
  flex-direction: column; 
  overflow: auto;
  margin: auto;
}

.shipbookColorLogo {
  width: 37px;
  height: 38px;
  object-fit: contain;
}

.title {
  font-size: 26px;
  color: var(--tealish);
  font-weight: 300;
}

.body {
  font-size: 14px;
  color: var(--brown-grey);
}

.fill {
  font-size: 14px;
  margin: 15px 0 15px 0;
  color: var(--brown-grey);
  font-weight: bold;
}

.form {
  display: flex;
  align-items: left;
  flex-direction: column; 
}

.label {
  font-size: 12px;
  color: var(--brown-grey);
}

.input, .inputEdit  {
  margin-top: 7px;
  height: 36px;
  width: calc(100% - 10px);
  font-size: 12px;
  text-align: left;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-radius: 5px;
  border: 0;
  padding: 0 5px;  
}

.inputEdit {
  width: 270px;
  margin-bottom: 7px;
  margin-right: 10px;
  background-image: url('../../img/preferences/pen.svg');
  background-repeat: no-repeat;
  background-size: 11.4px 13px;
  background-position: right 10px center;
}

.space {
  height: 35px;
}
.invite {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add {
  width: 26px;
  height: 26px;
}
.add:hover  {
  opacity: .8;
}

.submit  {
  margin-top: 20px;
  width: 120px;
	height: 36px;
  font-size: 12px;
  background-color: var(--marine);
  color: var(--fully-white);
  border-radius: 5px;
  cursor: pointer;
}
.submit:hover  {
  opacity: .8;
}

.error {
  height: 12px;
  font-size: 12px;
  color: var(--warm-pink);
  margin-bottom: 10px;
}


