.container {
  width: 346px;
  display: flex;
  align-items: center;
  flex-direction: column; 
  overflow-y: auto;
  margin: auto;
}

.problem {
  font-size: 14px;
  color: var(--brown-grey);  
  margin-top: 7px;
  align-self: start;
}
.problem a {
  color: var(--tealish);
  cursor: pointer;
}
.problem a:hover {
  text-decoration: underline;
}

.login {
  width: 100%;
  font-size: 14px;
  color: var(--brown-grey);
  text-decoration: none;
  margin-top: 5px;
  margin-left: 3px;
}

.login a {
  color: var(--marine);
  color: var(--tealish);
}

.login a:hover {
  text-decoration: underline;
}


.shipbookColorLogo {
  width: 346px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 20px;
}


.title {
  font-size: 26px;
  color: var(--tealish);
  font-weight: 300;
}

.body {
  font-size: 14px;
  color: var(--brown-grey); 
}

.email {
  margin-top: 20px;
  width: 100%;
}

.email label {
  font-size: 12px;
  color: var(--brown-grey);
}

.email input {
  margin: 7px 0px;
  height: 36px;
  width: calc(100% - 10px);
  font-size: 12px;
  text-align: left;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-radius: 5px;
  border: 0;
  padding: 0 5px;  
}

.resend {
  font-size: 12px;
  width: 100%;
  color: var(--brown-grey);
  margin-top: 20px;
  align-items: center;
}

.submit  {
  margin-top: 10px;
  width: 192px;
	height: 36px;
  font-size: 12px;
  background-color: var(--marine);
  color: var(--fully-white);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.submit:hover  {
  opacity: .8;
}

