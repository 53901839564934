.container {
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: var(--fully-white);
}

.main, .mainNotification{
  display: flex;
  width: 100%;
  
}
.main  {
  height: calc(100% - 68px);
}
.mainNotification {
  height: calc(100% - 68px - 24px); /* notification height 24px*/
}

.notification {
  background-color: var(--soft-pink);
  padding: 3px;
  color: var(--marine);
  font-size: 12px;
  text-align: center;
}

.notification a {
  font-weight: bold;
  text-decoration: underline;
  color: var(--marine);
}

.notification.free {
  background-color: var(--marine);
  color: var(--fully-white);
}

.notification.free a {
  color: var(--fully-white);
}

/* Navbar container */
.header {
  height: 67px;
  display: flex;
  flex-shrink: 0;
  border-bottom: solid 1px var(--pinkish-grey);
  padding: 0 11px;
  align-items: center;
}

.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 0 4px;
  text-decoration: none;
  border-radius: 50%;
  transition: 0.3s;
}

.menu:hover {
  background-color: var(--light-grey);
}

.menu img {
  flex: 1;
  opacity: 0.5;
}

.menu.selected img , .menu:hover img{
  opacity: 1 !important;
}

.menu:hover {
  background-color: var(--light-grey);
}

.menu div {
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background-color: var(--lipstick);
  color: var(--fully-white);
  border: 1px solid var(--fully-white);
  border-radius: 11px;
  position: absolute;
  top: 0px;
  left: 25px;
}

.padding {
  padding: 0 5px;
}

.logoShipbook {
  margin-right: 20px;
  width: 43.1px;
  height: 44.8px;
  object-fit: contain;
  cursor: pointer;
}

.menuIcon, .bridgeIcon {
	width: 28px;
  height: 28px;
  object-fit: contain;
  transition: 0.9s;
}

.bridgeIcon:hover {
  transform: rotate(180deg);
}

.text {
  color: var(--marine);
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;  
}

.memberIcon {
  height: 40px;
  width: 40px;
}


.accountAppComponent {
  /* flex: 1; */
  margin-left: 10px;
}

/* The dropdown container */
.dropdown {
  overflow: hidden;
}

/* Dropdown content (hidden by default) */
.dropdownContent {
  display: none;
  width: 285px;
  margin-left: -246px;
  position: absolute;
  background-color: var(--fully-white);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  color: var(--marine);
  padding-bottom: 25px;
  z-index: 1;
}

.dropDownAccountWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ice-blue);
  text-align: center;
  height : 65px;
}

.dropDownAccount {
  background-color: var(--tealish);
  width: 190px;
  border-radius: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
  color: var(--fully-white);
  text-decoration: none;
}

.dropDownAccount:hover {
  opacity: 0.8;
}

.dropDownMember {
  margin: 32px 0 0 26px;
  display: flex;
}

.dropDownSeparator {
  height: 1px;
  width: calc(100% - 30px);
  background-color: var(--pinkish-grey);
  margin: 8px 15px 8px 15px;
}

.dropDownMember .memberIcon {
  margin-right: 10px;
}

.dropDownUserName{
  font-size:16px;
  color:var(--black)
}

.dropDownUserEmail{
  font-size:12px;
  color:var(--brown-grey)
}

.dropDownOptionRegular{
  margin-left: 26px;
}

.dropDownOptionRegular a{
  text-decoration: none;
  color:var(--brown-grey);
  font-size: 12px;
  line-height: 18px;
}

.dropDownLogout {
  text-decoration: none;
  color:var(--marine);
  font-size: 12px;
  line-height: 18px;
  margin-left:  26px;
  cursor: pointer;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  display: block;
}

.referAFriend {
  margin: 0 10px 0 auto;
}
.referAFriend img{
  width: 90px;
  height: 39px;
}

.referAFriend:hover {
  opacity: 0.8;
}
