

.popupiInput {
  margin: 7px;
  width: 210px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 14x;
  font-weight: 100;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
}
textarea.popupInputArea {
  margin: 7px;
  width: 210px;
  height: 89px;
  border: 0px;
  border-radius: 4px;
  font-size: 14x;
  font-weight: 100;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.22);
  outline-style:none;
  border-color:transparent;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--marine);
}

.msg {
  font-size: 14px;
  flex-grow: 1;
  width: 260px;
  color: var(--brown-grey);
}