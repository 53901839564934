
@media (max-width: 794px) {
  .form { width: calc(100vw - 62px);}
}


.form {
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  width: 720px;
}

.form label {
  font-size: 12px;
  color: var(--brown-grey);
  margin-bottom: 3px;
}

.input {
  line-height: 33px;
  flex: 1;
  background-color: var(--fully-white);
  border: 0px;
  display: flex;
  padding: 0 5px;
  align-items: center;
  background-image: url('../../img/preferences/pen.svg');
  background-repeat: no-repeat;
  background-size: 11.4px 13px;
  background-position: right 10px center;
  color: var(--marine)
}

.error {
  height: 12px;
  font-size: 12px;
  color: var(--warm-pink);
  margin-bottom: 10px;
}


.integration {
  margin: 8px 0px;
  background-color: var(--fully-white);

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 55px;
}

.subTitle {
  font-size: 26px;
  font-weight: 300;
  color: var(--brown-grey);
}

.choosePlatform {
  margin: 0 11px 35px 11px;
  font-size: 14px;
  color: var(--marine);
}

.integration img {
  height: 89px;
  width: 89px;

  /* height: 60px;
  width: 65px; */
  margin: 0 11px;
  cursor: pointer;
}
