.component {
  margin: 23px var(--margin) 23px 20px;
  overflow-y: auto;
  max-width: 700px;
  padding-right: 40px;
}

.title {  
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.seperator { 
  border-top: 1px solid var(--light-grey);
  margin: 16px 0;
}

.general {
  display: grid;
  color: var(--brown-grey);
  font-size: 14px; 
  grid-template-columns: 146px 404px auto;
  grid-column-gap: 5px;
  align-items: center;
}

.general form {
  display: flex;
}

.general > input {
  margin: 7px 0px;
  height: 29px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border: solid 2px var(--marigold);
}

.general input::placeholder {
  color: var(--bb-grey);
}

.general > input:focus {
  /* box-shadow:2px 2px 2px 2px rgba(0,0,0,0.2); */
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.inputName {
  display: flex;
  flex: 1;
  border-color: transparent;
  border: 0px;
  margin: 7px 0px;
  height: 29px;
  border-radius: 4px;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
  align-items: center;
}

.inputName input {
  flex: 1;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.buttonName {
  margin: 7px 0px;
  background-color: var(--marine);
  height: 29px;
  border: none;
  font-size: 12px;
  margin-left: 10px;
  color: var(--fully-white);
}

.inputName img {
  width: 11.4px;
  height: 13px;
  object-fit: contain;
}

.subTitle {
  font-size: 22px;
  font-weight: 300;
  color: var(--tealish);
  margin-bottom: 20px;

  /* color: var(--brown-grey);
  font-weight: 300; */
}

.bodyHeader {
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 5px;
}

.normal {
  font-size: 14px;
  color: var(--brown-grey);
}

.icon {
  width: 20.8px;
  height: 25.5px;
  object-fit: contain;
}

.docs {
  margin-top: 20px;
  display: flex;
}
.openWizard, .openFull, .reset, .submit, .platform {
  display:block;
  width: 192px;
	height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.openWizard:hover, .openFull:hover, .reset:hover, .submit:hover, .platform:hover {
  opacity: .8;
}

.openWizard {
  background-color: var(--yellowy-green);  
  color: var(--marine);
  margin-right: 10px;
}
.openFull {
  background-color: var(--marine);
  color: var(--fully-white);
}

.platform {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 192px;
	height: 36px;
  line-height: 36px;

  background-color: var(--marine);
  text-decoration: none;
  color: var(--fully-white);
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.platformIcon { 
  width: 24px;
  height: 24px;
  object-fit: contain;
  padding-right: 10px;
}


.custom {
  display: flex;
  align-items: center;
}

.load {
  display: inline;
  font-size: 18px;
  color: var(--brown-grey);
}

.loadIcon {
  width: 28.5px;
  height: 27.6px;
  object-fit: contain;
  margin: 0 5px;
}

 
input:disabled {
  opacity: 0.35;
}

.notification {
  font-size: 18px;
  color: var(--marine);
  padding-bottom: 45px;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.notification span {
  font-size: 12px;
}

.notification input {
  margin-left: 25px;
}
