.component {
  margin: 23px var(--margin) 23px 20px;
  overflow-y: auto;
  max-width: 700px;
  padding-right: 40px;
}

.subTitle {
  font-size: 22px;
  font-weight: 300;
  color: var(--tealish);
  margin-bottom: 20px;

  /* color: var(--brown-grey);
  font-weight: 300; */
}

.bodyHeader {
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 5px;
}

.dsym, .addFile{
  width: 521px;
  height: 58px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  margin: 3px 3px 3px 0;
  color: var(--brown-grey);
}

.dsym div {
  flex: 1;
}

.dsym:nth-child(even) {
  background-color: var(--fully-white);
}

.dsym:nth-child(odd) {
  background-color:  var(--light-grey);
}


.addFile {
  background-color:  var(--light-grey);
  cursor: pointer;
}

.addFile input {
  margin: 5px;
  height: 26px;
  width: 100px;
  padding: 0 5px;
  border: none;
}

.addFile input::placeholder {
  color: var(--pinkish-grey);
}

.addFile button {
  color: var(--marine);
  padding: 0;
  border: none;
  background: none;
  margin: 0 10px 0 21px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}

.space {
  flex: 1;
}
.addFile button:disabled {
  color:  var(--pinkish-grey);
}

.breakLine {
  width: 541px;
  height: 0;
  margin: 20px 0;
  border-bottom: dotted 3px var(--pinkish-grey);
}

.dragDrop {
  width: 200px;
  height: 45px;
  border: dashed 2px var(--brown-grey);
  font-size: 12px;
  color: var(--brown-grey);
  font-weight: 300;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dragDrop span {
  color: var(--tealish);
  text-decoration: underline;
}
