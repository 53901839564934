.component {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
}

.header {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--pinkish-grey);
  background-color: var(--ice-blue);
}

.key {
  margin-left: 24px;
  font-size: 12px;
  color: var(--marine);
}

.dateSpan {
  flex: 1;
  font-size: 14px;
  color: var(--marine);
  text-align: center;
}
.versionLabel {
  font-size: 12px;
  color: var(--brown-grey);
  margin-right: 5px;
}

.moreMenu {
  position: relative;
  margin-right: 11px;
  display: flex;
}

.moreMenu img{
  width: 14px;
  height: 14px;
  padding: 5px;
  object-fit: contain; 
  border-radius: 50%;
  transition: .3s;
  cursor: pointer;
}

.moreMenu img:hover {
  background-color: var(--pinkish-grey);
}

.moreMenuContent {
  overflow: auto;
  display: block;
  max-height: 300px;
  min-width: 150px;
  position: absolute;
  background-color: var(--fully-white);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  color: var(--marine);
  right: 0px;
  top: 28px;
  z-index: 10;
}

.moreMenuContent div {
  padding: 10px;
  cursor: pointer;  
}

.moreMenuContent div:hover {
  background-color: var(--pinkish-grey)
}

.mainGraph {
  background-color: var(--fully-white);
  border-bottom: solid 1px var(--bb-grey);
}

.expandable {
  height: 100px;
  display: flex;
  flex-direction: row;
}

.expandable.hidden {
  height: 10px;
  visibility: hidden;
  transition: height .15s linear;
}

.leftInfo {
  width: 240px;
  margin-top: 13px;
  display: flex;
  align-items: center;
}

.severity {
 position: relative;
 margin: 0 25px;
}

.severity img {
  width: 52px;
  height: 52px;
}

.severity div {
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background-color: var(--lipstick);
  color: var(--fully-white);
  border: 1px solid var(--fully-white);
  border-radius: 11px;
  position: absolute;
  top: 0px;
  left: 25px;
}

.padding {
  padding: 0 5px;
}

.infoMain {
  margin-right: 30px;
  justify-content: space-between;
}

.infoPercentage {
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
}

.infoText {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color:  var(--bb-grey);
  width: 100%;
  justify-content: space-between;
}

.infoText div:last-child {
  font-size: 14px;
  color: var(--marine);
}

.chart {
  width: calc(100% - 20px);
}
/* severity */
.severityList {
  display: flex;
  flex-direction: row;  
}

.severityButton {
  position: relative;
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  transition: .3s;
}

.severityButton:hover {
  background-color: var(--pinkish-grey);
}

.severityButton img {
  margin: auto; 
}
.severityButton div {
  width: 7px;
  height: 7px;
  background-color: var(--lipstick);
  color: var(--fully-white);
  border: 1px solid var(--fully-white);
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 18px;
}


.severityOff {
  opacity: 0.25;
}

.severityIcon {
  width: 21px;
  height: 21px;
	object-fit: contain;
}

/* expansion */
.expansionWrapper {
  display: flex;
  flex-direction: column;
}
.expansion {
  width: 23px;
  height: 17px;
  margin-left: auto;
  margin-right: 12px;
  object-fit: contain;
  cursor: pointer;
}

/* LoglyticsList */
.customTooltip {
  box-shadow: 0 1px 7px 0 #b2b2b2;
  background-color: var(--fully-white);
  /* backdrop-filter: blur(2px);  */
  padding: 15px;
  margin: 0 100px 0 100px;
  display: grid;
  grid-template-columns: max-content min-content max-content;
  align-items: center;
  z-index: 10000;  
}

.customTooltipTitle {
  font-size: 12px;
  color: var(--marine);
  margin-bottom: 15px;
  text-align: center;
  grid-column: 1 / -1;
}

.customTooltipText {
  font-size: 12px;
  color: #b2b2b2;
}

.customTooltipColor {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 15px;
}

.loglyticsList {
  padding: 11px 7px;
  flex: 1;
}