.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}


.title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}


.subTitle {
  font-size: 14px;
  margin: 10px 0  30px 0;
  color: var(--marine);
}


.userBorder {
  height: 40px;
  width: 40px;
  /* border: solid 2px var(--marine);
  border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.clickable{
  cursor: pointer;
}
.seperator { 
  border-top: 1px solid var(--brown-grey);
  margin: 16px 0;
}

.table {
  color: var(--brown-grey);
  width: 100%; 
  font-size: 18px;  
}
  
.table input {
  margin: 7px 0px;
  width: 380px;
  height: 29px;
  border: 0px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
}
.table input.no_padding {
  padding: 0px 0px;
}

.table input:disabled, .account-input:disabled, .reset:disabled, .submit:disabled {
  opacity: 0.35;
}

.table td:first-child {
  width: 146px;
}

.table input::placeholder {
  color: var(--bb-grey);
}

.table input:focus {
  /* box-shadow:2px 2px 2px 2px rgba(0,0,0,0.2); */
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.filterHolder {
  display: flex;
  padding: 10px;
  width: 387px;
}
.filterHolder div:first-child { 
  flex: 4;
}