.title {
  padding-left: 20px;
  font-size: 26px;
  color: var(--black);
  font-weight: 300;
}

.subTitle {
  margin: 0 20px 20px 20px;
  padding-bottom: 10px;
  color: var(--brown-grey);
  font-size: 12px;
  border-bottom: solid 1px var(--pinkish-grey);
}

.item {
  font-size: 14px;
  line-height: 40px;
  padding-left: 20px;
  color: var(--marine);
  text-decoration: none;
  transition: 0.3s;
}

.item:hover {
  background-color: var(--ice-blue);
}

.item.selected {
  background-color: var(--tealish);
  color: var(--fully-white);
}
.space {
  flex: 1;
}

.referAFriend{
  margin: 10px 20px 10px 20px;  
  text-decoration: none;
  font-family: Heebo;
  font-size: 12px;
  color: var(--black);
}

.referAFriend:hover {
  text-decoration: underline;
}

.menuLinkWrapper {
  display: flex;
  margin: 0 20px 0 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  height : 65px;
  border-top: solid 1px var(--pinkish-grey);
}

.menuLink {
  background-color: var(--tealish);
  width: 190px;
  border-radius: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
  color: var(--fully-white);
  text-decoration: none;
}

.menuLink:hover {
  opacity: 0.8;
}

.disabled {
  opacity: 0.35;
  cursor: auto;
}


