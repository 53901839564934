.wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  z-index: 100;
}

.popup {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: 12px;
  max-width: 80%;
  min-height: 150px;
  backdrop-filter: blur(2px);
  box-shadow: 0 0 19px 0 var(--brown-grey);
  background-color: var(--light-grey);
}

.icon {
  display: flex;
  justify-content: center;
  border-right: solid 1px var(--pinkish-grey);
}

.icon img {
  align-self: center;
  margin:auto;
}

.innerPopup {
  display: flex;
  flex-direction: column; 
  max-width: calc(100% - 40px);
  margin-left: 21px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--marine);
}

.msg {
  font-size: 14px;
  flex-grow: 1;
  max-width: 260px;
  color: var(--brown-grey);
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.space {
  flex-grow: 1;
}

.buttonOk, .buttonCancel {
  width: 77px;
  height: 30px;
  font-size: 14px;
  align-self: center;
  color: var(--fully-white);
  margin-left: 5px;
  border: 0;
}


.buttonOk {
  background-color: var(--marine);
}

.buttonOk:hover {
  background-color: var(--marigold);
}

.buttonCancel {
  background-color: var(--bb-grey);
}

.buttonCancel:hover {
  background-color: var(--marigold);
}
