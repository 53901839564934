.component{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--fully-white);
    overflow-y: scroll;
}

.popup {
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(2px);
    box-shadow: 0 0 19px 0 var(--brown-grey);
    /* backdrop-filter: blur(30px); */
    /* box-shadow: 7px 7px 7px 0 rgba(0, 0, 0, 0.16); */
    background-color: var(--fully-white);
    margin: 20px 20px 20px 50px;  
    max-width: 100%;
    width: max-content;
    /* height: max-content; */

}

.header {
  height: 55px;
  background-color: var(--ice-blue);
  border-bottom: solid 1px var(--pinkish-grey);
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.title {
  flex: 1; 
  color: var(--tealish);
  font-size: 30px;
  font-weight: 300;
}

.titleCenter {
  flex: 1; 
  color: var(--tealish);
  font-size: 30px;
  font-weight: 300;
}

.xIcon {
  width: 15px;
  height: 15px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
}

.xIcon:hover {
  background-color: var(--light-grey);
} 

.body {
  /* position: absolute; */
  position: relative;
  background-color: var(--light-grey);
}