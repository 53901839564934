
.component {
  display: flex;

  flex: 1;
  flex-direction: row;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
