.component {
  margin: 0 0 10px 0;
}

.title {  
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 380px;
  grid-column-gap: 16px;
}

.name, .cardNumber, .error {
  grid-column-start: 1;
  grid-column-end: 4;
}


.name input, .country div, .cardNumber div, .securityCode div, .experation div {
  margin: 7px 0px;
  height: 29px;
  width: calc(100% - 20px);
  border: 0px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 300;
  padding: 0 10px;
  color: var(--marine);
  background-color: var(--light-grey);
} 

.nameInput input{
  width: calc(50% - 30px);
}
 .nameInput {
   display: flex;
   justify-content: space-between;
 }

.backgroundWhite .name input, .backgroundWhite .country div, .backgroundWhite .cardNumber div, .backgroundWhite .securityCode div, .backgroundWhite .experation div {
  background-color: var(--fully-white);
}

.name input {
  display: block;
}

.seperator { 
  border-top: 1px solid var(--brown-grey);
  margin: 16px 0;
}

.note {
  font-size: 14px;
}

.error {
  color: var(--lipstick);
  font-size: 12px;
  min-height: 18px;
}

.chatWithUs {
  margin-top: 10px;
  font-size: 14px;
  color: var(--tealish);
}

.chatWithUs a {
  text-decoration: underline;
  cursor: pointer;
}

