.component {
  position: relative;
  width: 100%;
  height: 100%;
}

.row {
  position: relative;
  width: 100%;
  height: 100%;
}

.dateRow {
  background-color: var(--ice-blue-dark);
  line-height: 34px;
  padding-left: 24px;
  width: 100%;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  color: var(--black);
}

.alertDate {
  width: 75px;
  font-size: 14px;
  color: var(--tealish);
}

.alertCount {
  width: 65px;
  font-size: 14px;
  color: var(--lipstick);
}

.highlight {
  border: 1px solid var(--lipstick);
}
.read {
  color: var(--brown-grey);
}


.customAlertRow {
  height: 49px;
}

.customAlertRow, .loglyticsAlertRow {
  display: flex;
  flex-direction: row;  
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
}
.customAlertRow:after, .loglyticsAlertRow:after {
  content : "";
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 0;
  height: 0;
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
}


.customAlertRow:hover {
  background-color: var(--light-grey);
}

.customAlertNC {
  flex: 1;
}
.customAlertName {
  font-size: 14px;
  color: var(--tealish);
}

.customAlertCondition {
  font-size: 12px;
  color: var(--brown-grey);
}

.customAlertSeverities {
  margin: 0 10px 0 25px;
}

.loglyticsAlertRow {
  height: 31px;
}

.loglyticsAlertRow:hover {
  background-color: var(--ice-blue);
}

.loglyticsAlertMessage {
  flex: 1;
  font-size: 14px;
  color: var(--brown-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loglyticsAlertLocation {
  width: 400px;
  font-size: 12px;
  color: var(--brown-grey);
}

.emptyAlerts {
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--fully-white);
}

.emptyAlerts img {
  margin-bottom: 5px;
}

.emptyAlerts div {
  font-size: 18px;
  font-weight: bold;
  color: var(--marine);
}

.emptyAlerts div div:last-child {
  font-size: 16px;
  font-weight: normal;
  white-space: pre-wrap;
}