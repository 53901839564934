
.switch, .switchSmall {
  background-color: var(--very-light-pink);
}

.switch {
  height: 31px;
  border-radius: 16px;
 }

 .switchSmall {
   height: 22px;
   width: 45px;
   border-radius: 11px;
 }
 
 .switch img, .switchSmall img{
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
  transition: .3s;
  margin: 2px;
 }

 .switch img {
   width: 27px;
   height: 27px;
   opacity: .25;
 }

 .switchSmall img{
  width: 18px;
  height: 18px;
  opacity: 0;
 }
 
 .switchSelected {
   background-color: var(--robin-s-egg);
   opacity: 1 !important;
 }
 
 .switch img:hover {
   background-color: var(--bb-grey);
   opacity: 1 !important;
 }
 