.component {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid var(--pinkish-grey);
  background-color: var(--ice-blue);
}

.dateSpan {
  flex: 1;
  font-size: 14px;
  color: var(--marine);
  text-align: center;
}

.versionLabel {
  font-size: 12px;
  color: var(--brown-grey);
  margin-right: 5px;
}

.unitChoices {
  margin-left: 100px;
  display: flex;
}

.unitChoices button {
  width: 58px;
  height: 24px;
  text-align: center;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  background: none;
  font-size: 12px;
  color: var(--marine);
  cursor: pointer;
}

.unitChoices button:hover {
  background-color: var(--light-grey) !important;
}

.unitSelected {
  background-color: var(--marigold) !important;
}

.seperator {
  background-color: var(--pinkish-grey);
  height: 23px;
  width: 1px;
  margin: 0 9px;
}

.body {
  background-color: var(--light-grey);
  padding: 17px 22px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.firstRow {
  display: flex;
  height: 177px;
}

.firstRowBody {
  margin-top: 8px;
  display: flex;
  height: 132px;
}


.chartSection {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chart {
  margin-bottom: 20px;
}

.percentage {
  width: 176px;
  height: 112px;
  padding: 10px 6px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: var(--fully-white);
  display: flex;
  flex-direction: column;
}

.percentageTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
  color: var(--brown-grey);
}

.percentageCenter {
  display: flex;
  flex: 1;
}

.percentageImage {
  width: 52px;
  height: 52px;  
  object-fit: contain;
  align-self: center;
}

.percentageText {
  font-size: 46px;
  font-weight: 300;
  margin: auto 0;
}

.percentageBar {
  background-color: #ebebeb;
  height: 8px;
  width: 170px;
}

.percentageBar div {
  height: 8px;
}


.loglytics {
  flex:1;
}
.chartTitle {
  font-size: 12px;
  color: var(--brown-grey);
}

.graphs {
  width: calc(100%-30px);
  padding: 10px 15px 150px 15px;
  background-color: var(--fully-white);
  border-radius: 5px;
  overflow: auto;
  flex: 1;
}

.customTooltip {
  box-shadow: 0 1px 7px 0 #b2b2b2;
  background-color: var(--fully-white);
  /* backdrop-filter: blur(2px);  */
  padding: 15px;
  margin: 0 100px 0 100px;
  display: grid;
  grid-template-columns: max-content min-content max-content;
  align-items: center;
  z-index: 10000;
}

.customTooltipTitle {
  font-size: 12px;
  color: var(--marine);
  margin-bottom: 15px;
  text-align: center;
  grid-column: 1 / -1;
}

.customTooltipText {
  font-size: 12px;
  color: #b2b2b2;
}

.customTooltipColor {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 15px;
}

.customTooltipTotal {
  grid-column: 1 / -1;
  font-size: 12px;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--brown-grey);
}
