.chatView {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.emptyMessages {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 108px;
}

.emptyTitle {
  margin: 47px 0 20px 0;
  font-size: 35px;
  color: var(--brown-grey);
}

.emptyDetail {
  font-size: 17px;
  margin-bottom: 70px;
  color: var(--brown-grey);
}

.emptyQuestion {
  font-size: 14px;
  width: 455px;
  box-sizing: content-box;
  margin-bottom: 10px;
  color: var(--brown-grey);
  background-color: var(--fully-white);
  border: solid 1px var(--marigold);
  color: var(--brown-grey);
  padding: 12px;
  cursor: pointer;
}

.emptyQuestion:hover {
  background-color: var(--ice-blue);
}

.logsHeader {
  background-color: var(--ice-blue); 
  height: 40px;
}

.nameForm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputChatName {
  width: 100%;
  display: flex;
  border-color: transparent;
  border: 0px;
  height: 40px;
  align-items: center;
  margin: 0 27px;
}

.inputChatName input {
  background: none;
  border: none;
  color: var(--marine);
  font-size: 14px;
  flex: 1;
}

.inputChatName img {
  width: 11.4px;
  height: 13px;
  object-fit: contain;
}

.seperator {
  width: 0;
  height: 30px;
  border-left : dashed 1px var(--brown-grey);
  margin: 0 16px;
}


.inputNameSubmit {
  height: 16px;
  width: 16px;
  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  transition: 0.3s;
}

.inputNameSubmit:hover {
  background-color: var(--ice-blue);
}

.messages {
  flex: 1;
  padding: 12px 0px;
}

.message {
  display: flex;
  white-space: pre-wrap;
  color: var(--brown-grey);
  padding: 35px 20px 20px 22px;
}

.message img {
  width: 35px;
  height: 35px;
}

.message div {
  margin-left: 14px;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.cursor {
  width: 5px;
  height: 20px;
  background: var(--brown-grey);;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

.user {
  background-color: var(--fully-white);
  cursor: pointer;
}
.user:hover {
  background-color: var(--ice-blue);
}

.bot {
  background-color: var(--light-grey);
}

.scrolledUser {
  background-color: var(--ice-blue-dark);
  color: var(--tealish);
}

.bottom {
  margin: auto 27px 12px 27px;
  height: 75px;
  padding: 10px 0px;
}

.messageText {
  height: 50px;
  display: flex;
  border: solid 2px var(--tealish);
  align-items: center;  
} 

.inputText {
  flex: 1;
  margin: auto 22px;
  border: none;
  background-color: var(--light-grey);
  font-size: 14px;
  color: var(--brown-grey);
}

.inputText::placeholder {
  color: var(--bb-grey);
}

.inputButton {
  width: 20px;
  height: 18px;
  margin-right: 20px;
}
