.component {
  display: flex;
  flex-direction: row;
  height: 64px;
  padding: 0 12px;
  background-color: var(--fully-white);
  align-items: center;
  border-bottom: solid 1px var(--bb-grey);
}

/* severity */
.severityList {
  display: flex;
  flex-direction: row;  
  margin-right: 16px;
}

.severityButton {
  display: flex;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  transition: .3s;
  cursor: pointer;
}

.severityButton:hover {
  background-color: var(--light-grey);
}

.severityButton img {
  margin: auto; 
}

.severityOff {
  opacity: 0.25;
}
.severityIcon {
  width: 35px;
  height: 35px;
}

.refresh, .play {
  margin-left: 16px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.refresh img {
  width: 27px;
  height: 27px;
  margin: 3px;
  transition: 0.9s;
}

.refresh img:hover {
  transform: rotate(360deg);
}

.play img {
  width: 27px;
  height: 27px;
  margin: 3px;
}

.seperator {
  width: 0;
  height: 30px;
  border-left : dashed 1px var(--brown-grey);
  margin: 0 16px;
}

.logChatButton {
  width: 31px;
  height: 31px;
}

.logChatButton:hover {
  opacity: 0.7;
}