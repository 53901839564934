.component {
  flex: 1;
  position: relative;
  margin: 13px 12px;
  padding: 23px;
  background: var(--fully-white);
  overflow: auto;
  font-family: Monospace;
}

.logs {
  overflow: auto;
  white-space: pre-wrap;
}