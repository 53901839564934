
/* The dropdown container */
.dropdown {
  display: flex;
  overflow: visible;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* Dropdown content (hidden by default) */
.dropdownContent {
  left: -70px;
  overflow: auto;
  display: block;
  max-height: 300px;
  min-width: 100px;
  max-width: 140px;
  position: absolute;
  background-color: var(--fully-white);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  color: var(--marine);
  z-index: 10;
}

.dropdownContent div {
  text-transform: lowercase;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownContent div:first-letter {
  text-transform: uppercase;
}

.dropdownContent div:hover {
  background-color: var(--pinkish-grey)
}

.dropdownContent div img {
  height: 15px;
  width: 15px;
  margin-right: 12px;
}

.select {
  flex: 1;
  min-width: 70px;
  font-size: 14px;
  color: var(--marine);
  display:inline-block;
  text-transform: lowercase;
}

.select:first-letter {
  text-transform: uppercase;
}

.centered {
  text-align: center
}

.extraTriangle {
  object-fit: contain;
  min-width: 100%;
  min-height: 100%
}
