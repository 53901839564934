.component {
  position: relative;
  width: 300px;
  min-width: 300px;
  height: 100%;
  background-color: var(--light-grey);  
  border-right: solid 1px var(--pinkish-grey);
  transition: width 0.15s linear;
}

.component.hidden{
  width: 20px;
  min-width: 20px;
  transition: width 0.15s linear;
}

.menu {
  width : 100%;
  height: calc(100% - (23px + 5px));
  min-width: max-content;
  display: flex;
  flex-direction: column;
  padding: 23px 0px 5px 0;
  align-items: left;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.15s linear;
}

.menu.hidden {
  visibility: hidden;
  opacity: 0  ;
  transition: opacity 0.15s linear;
}

.expansion {
  position: absolute;
  right: 0px;
  top: 23px;
  width: 17px;
  object-fit: contain;
  cursor: pointer;
}
