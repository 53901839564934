.logsView {
  background-color: var(--fully-white);
  flex: .4;
  min-width: 262px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto; 
}
.logsHeader {
  display: flex;
  background-color: var(--ice-blue-dark); 
  height: 40px;
  justify-content: space-between;
  align-items: center;
}

.logsTitle {
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  color: var(--tealish);
  font-size: 14px;
  margin: 0 11px;
}

.toLogBook {
  cursor: pointer;
  margin-right: 9px;
  padding-top: 7px;
  width: 12.2px;
  min-width: 12.2px;
  height: 13.4px;
  object-fit: contain;
  padding: 5px;
  border-radius: 50%;
  transition: .3s;
}

.toLogBook:hover {
  background-color: var(--bb-grey);
}


.logsBody { 
  padding: 10px;
  flex: 1;
  box-sizing: border-box;
}

.keyValueList {
  margin: 7px 0;
}

.logsWarning {
  border-top: solid 1px var(--pinkish-grey);
  color: var(--brown-grey);
  font-size: 11px;
  padding: 13px 0;
}

.logs {
  font-size: 14px;
  color: var(--brown-grey);
  overflow-y: scroll;
  white-space: pre-wrap;
  font-family: Monospace;;
}

.emptyLogsView {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.emptyBot {
  height: 121px;
  width: 121px;
  margin: 56px auto 70px 30px;
}

.emptyText {
  padding: 10px 30px;
  font-size: 14px;
  color: var(--brown-grey);
  font-family: Monospace;;
}