/* fixing bug with time picker https://github.com/Hacker0x01/react-datepicker/issues/1398 */
:global ul.react-datepicker__time-list {
	padding: 0;
}

/* overiting changes */
:global .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--marine);
	color: var(--marigold);
  font-weight: bold;
}

:global .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--marine);
	color: var(--marigold);
}

:global .react-datepicker__day--selected {
	background-color: var(--marine);
	color: var(--marigold);
}
