.copy {
  display: flex;
  align-items: center;
}
.copyIcon {
  cursor: pointer;
  width: 16.6px;
  height: 19.8px;
  object-fit: contain;
}

.copied {
  color: var(--brown-grey);
  font-size: 14px;
  margin-left: 10px;
}
