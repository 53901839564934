.container {
  width: 346px;
  display: flex;
  align-items: center;
  flex-direction: column; 
  overflow-y: auto;
  margin: auto;
}

.login {
  font-size: 14px;
  color: var(--brown-grey);
  text-decoration: none;
  margin-top: 5px;
  margin-left: 3px;
}

.login a {
  color: var(--marine);
  color: var(--tealish);
}

.login a:hover {
  text-decoration: underline;
}

.shipbookColorLogo {
  width: 96px;
  height: 72px;
  object-fit: contain;
  margin-bottom: 25px;
}


.sso {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
}

.or {
  display: flex;
  width: 100%;
  font-size: 12x;
  color: var(--bb-grey);
  align-items: center;
  text-align: center;;
}

.line {
  height: 1px; 
  width: 155px;
  padding-top: 3px;
  border-bottom: 1px solid var(--bb-grey);
}

.form {
  margin: 20px;
  width: 100%;
  display: flex;
  flex-direction: column; 
}

.label {
  margin-left: 3px;
  font-size: 12px;
  color: var(--brown-grey);
}

.input {
  margin: 3px 0px 0px 0px;
  height: 36px;
  width: calc(100% - 16px);
  font-size: 12px;
  text-align: left;
  color: var(--marine);
  background-color: var(--light-grey);
  outline-style:none;
  box-shadow:none;
  border-radius: 5px;
  border: 0;
  padding: 0 8px;  
}

.submit  {
  margin-top: 10px;
  width: 192px;
	height: 36px;
  font-size: 12px;
  background-color: var(--marine);
  color: var(--fully-white);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.submit:hover  {
  opacity: .8;
}


.terms {
  margin-left: 3px;
  font-size: 12px;
  color: var(--brown-grey);
  margin-top: 20px;
}

.terms a {
  color: var(--marine);
  text-decoration: none;
}
.terms a:hover {
  color: var(--marine);
  text-decoration: underline;
}

.error {
  min-height: 12px;
  font-size: 12px;
  color: var(--warm-pink);
  margin-bottom: 10px;
}

.space {
  flex: 1;
}