/* tooltip */
.tooltip {
  pointer-events: auto !important;
  background-color: var(--tealish) !important;
}

.tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.tooltip.place-bottom::after {
  border-bottom-color: var(--tealish) !important;
}

.tooltip.place-top::after {
  border-top-color: var(--tealish) !important; 
}

.tooltip.place-left::after {
  border-left-color: var(--tealish) !important; 
}

.tooltip.place-right::after {
  border-right-color: var(--tealish) !important; 
}


