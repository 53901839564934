.component {  
  display: flex;
  flex-direction: column;
  padding:10px 45px;
}

.description {
  font-size: 14px;
  color: var(--brown-grey);
  margin: 10px 0 22px 0;
  max-width: 700px;
}

.subTitle {
  font-size: 18px;
  color: var(--brown-grey);
  margin-bottom: 9px;
  margin-top: 20px;
}

.input, .inputName{
  color: var(--marine);
  border: 0;
  width: 120px;
  height: 30px;
  padding: 0 5px 0 5px;
  border: solid 1px var(--pinkish-grey);
  background-color: var(--fully-white);
}

.input:hover, .inputName:hover { 
  border-color: var(--brown-grey)
},

.inputName{ 
  width: 350px;
}

.input::placeholder {
  color: var(--bb-grey);
}

.logSearch {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
}
.trigger {
  display: flex;
  flex-direction: row;
  color: var(--brown-grey);
  align-items: center;
}

.trigger * {
  margin-right: 10px;
}

.select {
  height: 30px;
  width: 150px;
  padding: 0 5px 0 5px;
  border: solid 1px var(--pinkish-grey);
  background-color: var(--fully-white);
}

.select:hover {
  border-color: var(--brown-grey)
}
