.component {
  margin: 26px 0 26px 0;
}

.title {  
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.creditCard {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  
  color: var(--marine);
}
.creditCard img {
  margin-right: 156px;
  width: 66px;
  height: 46px;
}

.expires {
  color: var(--brown-grey);
  font-size: 14px;
  font-weight: 300;
}

.buttonWrapper {
  display: flex;
}

.space {
  flex: 1;
}

.component button {
  margin-top: 20px;
  width: 119px;
	height: 38px;
  font-size: 18px;
  background-color: var(--marine);
  color: var(--fully-white);
  float: right;
  cursor: pointer;
}
.component button:hover {
  background-color: var(--brown-grey);
}
