.icon {
  height: 40px;
  width: 40px;
  font-size: 32px;
  text-align:center;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 40px;
  color: var(--marine);
  background-color: var(--fully-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin: 5px;
}
