.component {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  margin: 7px 20px;
  align-items: center;
}

.showEvents {
	font-size: 12px;
  color: var(--marine);
  margin-left: 5px;
}

.showEventsImg {
  border-right: solid 1px var(--bb-grey);
  padding-right: 15.5px;
  margin: 10px;
	width: 17.8px;
	height: 15.2px;
  object-fit: contain;
  cursor: pointer;
}

.seperator {
  width: 0;
  height: 30px;
  border-left : dashed 1px var(--pinkish-grey);
  margin: 0 8px;
}

.trash {
  width: 31px;
  height: 31px;
  object-fit: contain;
}

.trash:hover {
  opacity: 0.7;
  cursor: pointer;
}