.planContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.planTrial {
  font-size: 12px;
  color: var(--marine);  
}

.planButton {
  box-sizing: border-box;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 114px;
  height: 142px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--fully-white);
  margin: 6px 0 15px 6px;
  cursor: pointer;
  transition: .3s;
}

.planButton.selected {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px var(--marigold);
  box-shadow: 0;
}

.planButton img {
  width: 100px;
  height: 100px;
  opacity: .4;
  transition: .3s;
}

.planButton:hover img, .planButton.selected img{
  opacity: 1;
}


.planButton div {
  font-size: 14px;
  font-weight: bold;
  color: var(--brown-grey);
}

.planPrice {
  grid-row: 2;
  height: 49px;
  font-size: 15px;
  color: var(--brown-grey);
  padding-bottom: 15px;
}

.planPrice span {
  font-size: 40px;
}

.last {
  border-right: 0 !important;
}

.planPrice.selected {
  color: var(--marine);
}

.planDetails {
  grid-row: 3;
  padding-right: 20px;
  font-size: 12px;
  color: var(--brown-grey)
}

.planDetails.selected {
  color: var(--marine);
}

.planDetails ul {
  font-weight: 500;
}

.disabled {
  opacity: 0.35;
}

.button {
  margin-top: 20px;
  width: 119px;
	height: 38px;
  font-size: 18px;
  background-color: var(--marine);
  color: var(--fully-white);
  cursor: pointer;
}
.button:hover {
  background-color: var(--brown-grey);
}

.button:disabled {
  opacity: 0.35;
}


.planList {
  display: grid;
  grid-auto-columns: minmax(200px, 300px);
  grid-column-gap: 40px; 
  column-rule: dashed 1px var(--pinkish-grey);
  overflow-y: auto;
  margin-bottom: 10px;

}