.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 19px 0 24px;
}

.title {
  font-size: 26px;
  
  color: var(--tealish);
}

.addChat {
  cursor: pointer;
  width: 40px;
  height: 36px;
  margin: 5px;
}

.addChat:hover {
  opacity: 0.7;
}

.underline {
  margin: 0 10px 0 24px;
  width: calc(100% - 34px);
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
}

.logChatList {
  flex: 1;
}

.logChatDate {
  color: var(--brown-grey);
  margin: 44px 0 0 24px;
  font-size: 11px;
  line-height: 17px;
  font-weight: bold;
  color: var(--tealish);
}

.logChatRow {
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 24px;
  cursor: pointer;
  box-sizing: border-box;
}

.logChatRow:hover {
  background-color: var(--pinkish-grey);
  
}

.logChatRow.selected {
  border: solid 1px var(--marigold);
}

.chatName {
  font-size: 14px;
  color: var(--brown-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trash {
  margin-left: auto;
  width: 11.4px;
  height: 13.3px;  
  padding-left: 35px;
  object-fit: contain;
  cursor: pointer;
  /* border-radius: 50%; */
  transition: .3s;
}

.trash:hover {
  opacity: 0.7;
  cursor: pointer;
}
