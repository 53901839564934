.component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.popup {
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 19px 0 var(--brown-grey);
  width: 95%;
  height: 80%;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--marine);
  width: 16px;
  height: 16px;
  padding: 8px;
  border-radius: 50%;

  object-fit: contain;
  cursor: pointer;
  transition: .3s;
}
.close:hover {
  background-color: var(--bb-grey);
}

.header {
  display: flex;
  height: 83px;
  border-bottom: 1px solid var(--brown-grey);
}

.severity {
  grid-row: 1 / 4 ;
  grid-column: 1 / 2;
  width: 11px;
}

.severityRound {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: auto 11px;
  display: inline-block;
  grid-area: 1 / 2 / 4 / 3;
}

.verbose {
  background-color:#b3e528;
}
.debug {
  background-color:#7becef;
}
.info {
  background-color:#ffc306;
}
.warning {
  background-color:#ff7336;
}
.error {
  background-color:#e51a41;
}
.exception {
  background-color:#000000;
}

.headerGrid {
  flex: 1;
  margin: 10px 12px 10px 0;
  display: grid;
  grid-template-rows: 21px 21px 21px;
  grid-template-columns: auto min-content auto min-content auto;
  overflow: auto;
  white-space: nowrap;

}

.date {
  font-size: 16px;
  font-weight: 800;
  color: var(--marine);
}

.time {
  font-size: 16px;
  font-weight: 300;
  color: var(--brown-grey);
}

.location {
  font-size: 12px;
  color: var(--tealish);
}

.gap {
  background: var(--pinkish-grey);
  width: 1px;
  grid-row: 1 / 4;
  margin: 0 15px;
}

.keyValue {
  display: flex;
}
.key {
  font-size: 12px;
  color: var(--brown-grey);
}
.value {
  margin-left: 5px;
  font-size: 12px;
  color: var(--brown-grey);
  font-weight: 300;
}

.orientation {
  width: 34.1px;
  height: 37.7px;
  object-fit: contain;
  grid-row: 2 / span 2;
  align-self: center;
}

.messageText {
  flex: 1;
  background-color: var(--fully-white);
  margin: 11px;
  padding: 14px;
  overflow-y: auto;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  
}

.messageText em {
  background-color: var(--off-yellow);
  font-style: normal;
}

.messageTextWrap {
  white-space: normal;
  overflow-wrap: break-word;
}

.bottom {
  margin: 0px 11px 5px 11px;
}
.wrapOn, .wrapOff {
  width: 27px;
  height: 27px;
  border-radius: 50%; 
  object-fit: contain;
  cursor: pointer;
  transition: .3s;
}

.wrapOn:hover, .wrapOff:hover  {
  background-color: var(--bb-grey);
  opacity: 1;
}
.wrapOn {
  background-color: var(--robin-s-egg);
}

.wrapOff {
  opacity: 0.25;
  background-color: var(--bb-grey);
}

