.container {
  max-width: 366px;
  min-height: 100%;
  display: flex;
  flex-direction: column; 
  overflow: auto;
  margin: auto;
  padding: 0 10px 0 10px;
}


.shipbookColorLogo {
  width: 37px;
  height: 39px;
  object-fit: contain;
  margin-top: 90px;
  margin-bottom: 20px;
}

.title {
  font-size: 26px;
  color: var(--tealish);
  font-weight: 300;
}

.body {
  font-size: 14px;
  color: var(--brown-grey); 
  margin: 10px 0 10px 0;
}

.body a {
  color: var(--tealish);
  cursor: pointer;
}
.body a:hover{
  text-decoration: underline;
}

.login {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  padding-top: 13px;
  color: var(--tealish);
  text-decoration: none;
}