* {--list-margin: 50px;}

.component {
  flex: 1;
  position: relative;
  margin: 0 12px;
  background: var(--fully-white);
}

.isLoglytics .sessionPlus, .isLoglytics .plus {
  visibility: hidden;
}

.sessionWrapper {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  transition: opacity .15s;
}

.sessionWrapper.hidden {
  /* visibility: hidden; */
  opacity: 0 !important;
}

.log {
  height: 57px;
  padding: 0 36px 0 8px ;
  display: grid;
  grid-template-rows: 26px 26px;
  grid-template-columns: 28px auto;
  align-items: center;
  cursor: pointer;
  justify-content: stretch;
  transition: .3s;
}

.log:hover {
  background: var(--light-grey);
}
.log:after {
  content : "";
  position: absolute;
  left: 36px;
  right: 36px;
  bottom: 0;
  height: 0;
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
}
.log.minMode {
  height: 28px;
  grid-template-rows: 26px;
}

.log.selected {
  border: solid 2px var(--marigold);
  box-sizing: border-box;
}

.severity {
  height: 12px;
  width: 12px;
  margin-left: 3px;
  border-radius: 50%;
  display: inline-block;
  grid-area: 1 / 1 / 3 / 2;
}

.severity.verbose {
  background-color: var(--yellowy-green);
}
.severity.debug {
  background-color: var(--robin-s-egg);
}
.severity.info {
  background-color: var(--off-yellow);
}
.severity.warning {
  background-color: var(--pumpkin-orange);
}
.severity.error {
  background-color: var(--lipstick);
}
.severity.exception {
  background-color: var(--black);
}

.logRow {
  display: flex;
  flex-direction: row;
  grid-column: 2 / 3;
  overflow: hidden
}

.logRow.header {
  align-self: end;
}

.logRow.parameters {
  align-self: start;
}

.time {
  font-size: 14px;
  color: var(--brown-grey);
  font-weight: 300;
  white-space: nowrap;
  margin-right: 5px;
}

.msg {
  font-size: 14px;
  color: var(--brown-grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.msg :first-child {
  font-weight: bold;
}
.msg em {
  font-style: normal;
}

.highlight, .msg em {
  background-color: var(--off-yellow);
}

.logKey {
  font-size: 13px;
  color: var(--brown-grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
}

.logValue {
  font-size: 13px;
  font-weight: 300;
  color: var(--brown-grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.seperator {
  font-size: 14px;
  margin: 0 5px 0 20px;
  color: var(--marine);
}

.viewMessage {
  cursor: pointer;
  padding: 2px 20px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.viewMessagePopup {
  overflow-y: auto;
  background: white;
  margin: 10px 0;
  max-height: 300px;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
}


.tosession {
  cursor: pointer;
  padding-top: 7px;
  width: 12.2px;
  min-width: 12.2px;
  height: 13.4px;
  object-fit: contain;
  padding: 5px;
  border-radius: 50%;
  transition: .3s;
}

.tosession:hover {
  background-color: var(--bb-grey);
}

.space {
  flex: 1;
}

.session {
  height: 52px;
  display: grid;
  grid-template-rows: 26px 26px;
  grid-column-gap: 20px;
  font-size: 12px;
  background-color: var(--ice-blue);
  justify-content: start;
  overflow: auto;
  white-space: nowrap;
}

.sessionTitle {
  grid-row: 1;
  font-weight: 300;
  padding-top: auto;
  color: var(--brown-grey);
  align-self: end;
}

.sessionValue {
  grid-row: 2;
  padding-bottom: auto;
  color: var(--brown-grey);
  display: flex;
  align-self: start;
}

.sessionCenter {
  grid-row: 1 / 3;
  display: flex;
  align-self: center;
  align-items: center;
}

.sessionDate {
  padding: 0 15px;
  grid-row: 1;
  font-size: 16px;
  font-weight: 800;
  color: var(--marine);
  background-color: var(--ice-blue-dark);
  align-self: end;
}

.sessionTime {
  padding: 0 15px;
  grid-row: 2;
  font-size: 12px;
  font-weight: 800;
  color: var(--tealish);
  background-color: var(--ice-blue-dark);
}

.sessionOsText{
  font-size: 16px;
  color: var(--brown-grey);
}
.sessionOsIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  object-fit: contain;
}

.sessionLanguage {
  margin: 0 auto;
  height: 28.4px;
  width: 28.4px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 50%;
  color: var(--brown-grey);
  border: solid 1px var(--brown-grey);
  text-align: center;
  vertical-align: middle;
  line-height: 28.4px;
  grid-row: 1 / 3;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sessionPlus {
  font-size: 12px;
  font-weight: bold;
  color: var(--marine);
  display:inline;
  cursor: pointer;
  padding: 0 5px;
  border-radius: 50%;
  transition: .3s;
}
.sessionPlus:hover {
  background: var(--bb-grey);
}

.event {
  height: 57px;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
}

/* .event:after {
  content : "";
  position: absolute;
  left: 34px;
  right: 20px;
  bottom: 0;
  height: 0;
  opacity: 0.4;
  border-bottom: solid 1px var(--pinkish-grey);
} */

.event.minMode {
  height: 28px;
}


.eventRow {
  display: flex;
  margin-left: 34px;
  flex-direction: row;
  align-items: center;
}

.eventRow div {
  display: flex;
  flex-direction: row;
}

.eventArrow {
  font-size: 14px;
  font-weight: 500;
  margin: 0 5px;
  color: var(--tealish);
}

.eventInfo {
  font-size: 14px;
  font-weight: 500;
  color: var(--brown-grey);
}

.loading {
  margin-left: var(--list-margin);
}

.plus {
  font-size: 12px;
  font-weight: bold;
  color: var(--marine);
  display:inline;
  cursor: pointer;
  padding: 0 5px;
  border-radius: 50%;
  transition: .3s;
}
.plus:hover {
  background: var(--bb-grey);
}

.noLogs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.noLogsText {
  margin-right: 68px;
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  color: var(--marine);
}

.noLogsTextBody {
  font-size: 18px;
  font-weight: normal;
}
