.component {
  margin: 23px var(--margin) 23px 20px;
  overflow-y: auto;
  max-width: 700px;
  padding-right: 40px;
}

.subTitle {
  font-size: 22px;
  font-weight: 300;
  color: var(--tealish);
  margin-bottom: 20px;

  /* color: var(--brown-grey);
  font-weight: 300; */
}

.bodyHeader {
  font-size: 14px;
  color: var(--marine);
  margin-bottom: 5px;
}

.configuration {
  margin: 20px 0 10px 0;
  display: flex;
  align-items: center;
}

.configuration label {
  margin-left: 0px;
  margin-right: 26px;
  font-size: 16px;
  color: var(--brown-grey);
}
.configuration input {
  margin: 0px 2px 2px 0;
}
.configuration input:checked + label {
  color: var(--marine);
  font-weight: boldi;
}

.showLogs {
  margin-top: 20px;
  align-items: center;
  display: flex;
}
.showLogs input {
  margin-right: 5px;
}
 
input:disabled {
  opacity: 0.35;
}
