.container {
  height: 36px;
  width: 170px;
  background-color: var(--black);
  color: var(--fully-white);
  font-size: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 3px;
}

.container:hover {
  opacity: 0.7; 
}

.container img {
  width: 18px;
  height: 18px;
  padding: 7px;
  margin: 0 4px 0 2px;
  object-fit: contain;
}