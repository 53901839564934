.component {
  margin: 23px var(--margin) 20px 20px;
  overflow: auto;
}

.title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.referral {
  margin: 23px 0 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: auto;
  row-gap: 6px;
  grid-template-areas: 
    "first second fourth"
    "first third fifth";  
  overflow: auto;
}

.memberIcon {
  grid-area: first;
  height: 40px;
  width: 40px;
  margin-right: 25px;
}

.referralCodeTitle {
  grid-area: second;

  font-size: 12px;
  color: var(--black);
}

.referralCode {
  grid-area: third;
  width: 248px;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  border: solid 1px var(--pinkish-grey);
  font-size: 12px;
  color: var(--black);
  margin-right: 14px;
}

.referralListTitle  {
  margin: 60px 0 20px 0;
  font-size: 26px;
  font-weight: 300;
  color: var(--tealish);
}

.referralRow {
  display: grid;
  width: 531px;;
  height: 70px;
  grid-template-columns: 200px 110px 1fr;
  grid-template-rows: auto;
  row-gap: 0px;
  grid-template-areas: 
    "name status amount"
    "email date gift";
  border-bottom: solid 1px var(--pinkish-grey);
}

.referralRowName {
  grid-area: name;
  font-size: 16px;
  color: var(--black);
  align-self: end;
}
.referralRowEmail {
  grid-area: email;
  font-size: 12px;
  color: var(--brown-grey);
}
.referralRowStatus {
  grid-area: status;
  font-size: 16px;
  color: var(--black);
  align-self: end;
}

.referralRowStatus.registered {
  color: var(--pumpkin-orange);
}

.referralRowStatus.paid {
  color: var(--gross-green);
}

.referralRowDate {
  grid-area: date;
  font-size: 12px;
  color: var(--brown-grey);
}
.referralRowAmount {
  display: flex;
  grid-area: amount;
  font-size: 16px;
  color: var(--gross-green);
  align-self: end;
  text-decoration: none;
}
.referralRowAmount img {
  height: 14px;
  width: 14px;
  margin-right: 2px;
  margin-top: 3px;
  object-fit: contain;
}

.referralRowGift {
  grid-area: gift;
  font-size: 12px;
  color: var(--brown-grey);
}



.shareTitle {
  grid-area: fourth;
  font-size: 12px;
  color: var(--tealish);
  
}

.share {
  grid-area: fifth;
  display: flex;
}

.share img {
  cursor: pointer;
  height: 21px;
  width: 21px;
  margin: 5px 10px 0 0;
}

.share img:hover   {
  opacity: 0.7;
}

.share img.email {
  width: 28px;
}

.comment {
  display: flex;
  margin: 23px 20px 0 100px;
}

.commentStar {
  font-size: 100px;
  font-family: HelveticaNeue;
  font-weight: 300;
  color: var(--tealish);
}

.commentText {
  margin-top: 60px;
  font-size: 15px;
  color: var(--black);
}

.commentText a {
  color: var(--tealish);
  text-decoration: none;
  /* font-size: 11px; */
}
.commentBubble {
  width: 353.9px;
  height: 290.1px;
  object-fit: contain;
}